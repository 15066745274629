<template>
  <div class="inner">
    <div class="flex-c">
      <component
        :is="entry.icon"
        v-if="entry.icon"
        :class="['enter-icon', { mobile: entry.path === '/download_app' }]"
      ></component>
      <div v-else class="enter-icon"></div>
      <div class="enter-title" v-text="entry.label"></div>
    </div>
    <img
      alt=""
      class="enter-arrow"
      src="@/assets/images/member_center/arrow-right.png"
    />
  </div>
</template>

<script>
import DepositlogIcon from '@/assets/icons/member-center/deposit-log.svg'
import MoneylogIcon from '@/assets/icons/member-center/money-log.svg'
import QrcodeIcon from '@/assets/icons/member-center/qrcode.svg'
import CustomerServiceIcon from '@/assets/icons/member-center/customer-service.svg'
import InfoIcon from '@/assets/icons/member-center/info.svg'
import MessageIcon from '@/assets/icons/member-center/message.svg'
import SettingIcon from '@/assets/icons/member-center/setting.svg'
import MobileIcon from '@/assets/icons/member-center/mobile.svg'
import ContactUsIcon from '@/assets/icons/member-center/contact-us.svg'
import PrivatePolicyIcon from '@/assets/icons/member-center/private-policy.svg'
import AgreementIcon from '@/assets/icons/member-center/agreement.svg'
import TransLogIcon from '@/assets/icons/member-center/trans-log.svg'
import BankCardIcon from '@/assets/icons/member-center/bank-card.svg'
import Save from '@/assets/icons/member-center/trans-log.svg'
import Withdraw from '@/assets/icons/member-center/trans-log.svg'
import Transfer from '@/assets/icons/member-center/trans-log.svg'

export default {
  name: 'menu-item-inner',
  props: ['entry'],
  components: {
    DepositlogIcon,
    MoneylogIcon,
    QrcodeIcon,
    CustomerServiceIcon,
    InfoIcon,
    MessageIcon,
    SettingIcon,
    MobileIcon,
    ContactUsIcon,
    PrivatePolicyIcon,
    AgreementIcon,
    TransLogIcon,
    BankCardIcon,
    Save,
    Withdraw,
    Transfer
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.inner {
  height: 100%;
  border-bottom: 1px solid #454555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .enter-icon {
    width: 1rem;
    margin: 0 0.5rem;

    &.mobile {
      width: 0.7rem;
      margin: 0 0.7rem;
    }

    path,
    g {
      fill: white;
    }
  }

  .enter-title {
    font-size: 0.8rem;
    color: white;
    letter-spacing: 0.2rem;
  }

  .enter-arrow {
    width: 0.5rem;
    display: none;
  }
}

@include md {
  .inner {
    // .enter-icon {
    //   width: 1rem;
    //   margin: 0 0.5rem;
    // }

    .enter-title {
      font-size: 0.8rem;
      letter-spacing: 0.2rem;
    }

    .enter-arrow {
      width: 0.5rem;
      display: block;
    }
  }
}
</style>
