var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-center"},[_c('img',{staticClass:"bg-img",attrs:{"alt":"","src":require("@/assets/images/login-bg-2.png")}}),(_vm.$store.state.isMobileLayout)?_c('img',{staticClass:"logo",attrs:{"src":_vm.$store.state.site.logo,"alt":""}}):_vm._e(),(!_vm.$store.state.isMobileLayout)?_c('page-header'):_vm._e(),_c('fixed-menu'),_c('div',{staticClass:"admin-content"},[_c('div',{staticClass:"member-nav"},[(!_vm.$store.state.isMobileLayout)?_c('Navigator'):_vm._e()],1),(!(_vm.$store.state.isMobileLayout && _vm.isAbout))?_c('div',{staticClass:"member-head"},[(_vm.userInfo.user_name && !_vm.isAbout && !_vm.$store.state.msgTitle)?_c('div',{staticClass:"user-info flex-c"},[_c('img',{staticClass:"avatar",attrs:{"src":require('@/assets' + _vm.userInfo.pic_url),"alt":""}}),_c('div',{staticClass:"info"},[_c('p',[_vm._v("会员 "+_vm._s(_vm.userInfo.nick_name))]),_c('p',[_vm._v("账号 "+_vm._s(_vm.userInfo.user_name))]),_c('p',[_vm._v("余额 "+_vm._s(_vm.userInfo.totalBalance))]),_c('p',[_vm._l((_vm.$store.state.gamemoney),function(item){return [_vm._v(" ["+_vm._s(item.game_name)+"]"+_vm._s(item.surBalance)+"    ")]})],2)])]):_vm._e(),_c('div',{staticClass:"head-title flex-c"},[(!_vm.isAbout && !_vm.$store.state.msgTitle)?_c('img',{staticClass:"crown",attrs:{"alt":"","src":require("@/assets/images/member_center/crown.png")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.state.msgTitle || (_vm.isAbout ? _vm.header_title : '会员中心'))+" ")])]):_vm._e(),(
        _vm.$store.state.isMobileLayout && _vm.$route.fullPath !== '/member_center'
      )?_c('div',{staticClass:"member-header flex-ac"},[_c('div',{staticClass:"back-btn",on:{"click":_vm.goBack}},[_c('img',{staticClass:"member-header-back",attrs:{"alt":"","src":require("@/assets/images/member_center/arrow-right.png")}})]),_c('p',{staticClass:"member-header-title"},[_vm._v(" "+_vm._s(_vm.header_title)+" ")])]):_vm._e(),_c('member-menu'),(
        _vm.$route.fullPath === '/member_center' && !_vm.$store.state.isMobileLayout
      )?_c('div',{staticClass:"center-home"},[_c('img',{attrs:{"src":_vm.$store.state.site.logo,"alt":""}})]):_c('div',{class:[
        'page-content',
        { 'is-about': _vm.$route.fullPath.indexOf('/about') > -1 },
        {
          'h5-notification':
            _vm.$store.state.isMobileLayout &&
            _vm.$route.fullPath.indexOf('/member_center/notification') > -1,
        } ]},[_c('router-view',{staticClass:"admin-page-content"})],1)],1),_c('suggest-browser'),_c('slogan'),_c('page-footer'),(_vm.$store.state.isMobileLayout)?_c('Navigator'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }