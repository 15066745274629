<template>
  <div class="member-center">
    <img alt="" class="bg-img" src="~@/assets/images/login-bg-2.png" />
    <img
      v-if="$store.state.isMobileLayout"
      :src="$store.state.site.logo"
      alt=""
      class="logo"
    />
    <page-header v-if="!$store.state.isMobileLayout" />
    <fixed-menu></fixed-menu>
    <div class="admin-content">
      <div class="member-nav">
        <Navigator v-if="!$store.state.isMobileLayout" />
      </div>
      <div v-if="!($store.state.isMobileLayout && isAbout)" class="member-head">
        <div
          v-if="userInfo.user_name && !isAbout && !$store.state.msgTitle"
          class="user-info flex-c"
        >
          <!-- <img :src="userInfo.avatar" alt="" class="avatar" /> -->
          <img
            :src="require('@/assets' + userInfo.pic_url)"
            alt=""
            class="avatar"
          />
          <div class="info">
            <p>会员&nbsp;{{ userInfo.nick_name }}</p>
            <p>账号&nbsp;{{ userInfo.user_name }}</p>
            <p>余额&nbsp;{{ userInfo.totalBalance }}</p>
            <p>
            <template v-for="item in $store.state.gamemoney">
                [{{item.game_name}}]{{item.surBalance}} &nbsp;&nbsp;
            </template>
            </p>
          </div>
        </div>
        <div class="head-title flex-c">
          <img
            v-if="!isAbout && !$store.state.msgTitle"
            alt=""
            class="crown"
            src="@/assets/images/member_center/crown.png"
          />
          {{ $store.state.msgTitle || (isAbout ? header_title : '会员中心') }}
        </div>
      </div>
      <!-- <div class="member-tabs flex-ac flex-jb" v-if="$store.state.isMobileLayout && $route.fullPath == '/member_center'">
        <div class="tab flex-c wrap">
          <img src="@/assets/images/member_center/save.png" alt="" class="tab-icon">
          <div class="tab-title">
            快速充值
          </div>
        </div>
        <div class="tab flex-c wrap">
          <img src="@/assets/images/member_center/withdraw.png" alt="" class="tab-icon">
          <div class="tab-title">
            申请提领
          </div>
        </div>
        <div class="tab flex-c wrap">
          <img src="@/assets/images/member_center/transfer.png" alt="" class="tab-icon">
          <div class="tab-title">
            额度转换
          </div>
        </div>
      </div> -->
      <div
        v-if="
          $store.state.isMobileLayout && $route.fullPath !== '/member_center'
        "
        class="member-header flex-ac"
      >
        <div class="back-btn" @click="goBack">
          <img
            alt=""
            class="member-header-back"
            src="@/assets/images/member_center/arrow-right.png"
          />
        </div>
        <p class="member-header-title">
          {{ header_title }}
        </p>
      </div>
      <member-menu></member-menu>
      <div
        v-if="
          $route.fullPath === '/member_center' && !$store.state.isMobileLayout
        "
        class="center-home"
      >
        <img :src="$store.state.site.logo" alt="" />
      </div>
      <div
        v-else
        :class="[
          'page-content',
          { 'is-about': $route.fullPath.indexOf('/about') > -1 },
          {
            'h5-notification':
              $store.state.isMobileLayout &&
              $route.fullPath.indexOf('/member_center/notification') > -1,
          },
        ]"
      >
        <router-view class="admin-page-content"></router-view>
      </div>
    </div>
    <suggest-browser></suggest-browser>
    <slogan></slogan>
    <page-footer />
    <Navigator v-if="$store.state.isMobileLayout" />
    <!-- <Footer v-if="!$store.state.isMobileLayout" /> -->
  </div>
</template>

<script>
import PageFooter from '@/components/page-footer.vue'
import Navigator from '@/components/Navigator.vue'
import FixedMenu from '@/components/fixed-menu.vue'
import PageHeader from '@/components/page-header.vue'
import Slogan from '@/components/slogan.vue'
import SuggestBrowser from '@/components/suggest-browser.vue'
import MemberMenu from './member_center/menu.vue'
import { scrollToTop } from '@/utils'
import usr from '@/mixins/usr'
import tabs from './about-tabs.json'

export default {
  name: 'member-center',
  mixins: [usr],
  data() {
    return {
      is_show_about_modal: false,
      is_show_private_modal: false,
      is_show_contract_modal: false,
      is_show_contact_modal: false,
      header_title: ''
    }
  },
  components: {
    PageHeader,
    PageFooter,
    Navigator,
    FixedMenu,
    Slogan,
    SuggestBrowser,
    MemberMenu
  },
  async created() {
    await this.getUserInfo() // 接口没有回传 token
    if (!this.userInfo.token) {
      await this.getGameList() // 为了拿 token，后端死不改
    }
  },
  mounted() {
    scrollToTop()
  },
  computed: {
    isAbout() {
      return this.$route.fullPath.indexOf('/about') > -1
    },
    routeName() {
      if (!this.isAbout) {
        return this.$route.meta.name
      } else {
        const target = tabs.find(tab => tab.name === this.$route.params.type)
        if (target) {
          return target.label
        }
        return target ? target.label : this.$route.meta.name
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.header_title = this.routeName
      }
    }
  },
  methods: {
    goBack() {
      if (this.$route.params.id) {
        return this.$router.back()
      }
      this.$router.push('/member_center')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.member-center {
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1f1b17;
  background-size: 100% 100%;

  .center-home {
    width: calc(100% - 11rem);
    padding: 5rem 0;
    height: 300px;

    > img {
      height: 7rem;
    }
  }
}

.logo {
  width: 10rem;
  position: relative;
  display: block;
  margin: 1rem auto 1rem 3rem;
}

.bg-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.member-head {
  width: calc(100% - 0.5rem);
  height: 8rem;
  margin: 0 auto 0.5rem;
  background-image: url('~@/assets/images/member_center/member_head.png');
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;

  > .user-info {
    position: absolute;
    left: 1.5rem;

    > .avatar {
      width: 4rem;
      margin-right: 0.6rem;
    }

    > .info {
      font-size: 1rem;
      color: white;
      text-align: left;
      margin-bottom: -1rem;

      > p {
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  > .head-title {
    font-size: 1.2rem;
    color: white;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }
}

.crown {
  width: 2rem;
  margin-right: 0.5rem;
}

.member-tabs {
  width: calc(100% - 0.5rem);
  margin: 0 auto 0.5rem;
}

.tab {
  width: 30%;
  height: 2.5rem;
  background-image: url('~@/assets/images/member_center/member_tab.png');
  background-size: 100% 100%;
}

.tab-icon {
  width: auto;
  height: 1.2rem;
}

.tab-title {
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  color: #333;
  letter-spacing: 0.3rem;
}

.member-nav {
  background-color: black;
  position: relative;
  margin-bottom: 1rem;
}

.admin-content {
  display: flex;
  justify-content: space-between;
  background-color: #292628;
  position: relative;
  flex-wrap: wrap;
}

.page-content {
  width: calc(100% - 11rem);
  min-height: 10rem;
  border-radius: 5px;
  background-color: #353545;
  position: relative;
  margin: 0 0.3rem 1rem;
  z-index: 1;

  &.is-about {
    width: 100%;
  }

  &.h5-notification {
    margin: 0;
  }
}

.admin-page-content {
  width: 100%;
  min-height: 100%;
  padding: 2rem 5%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}

@include md {
  .links {
    display: none;
  }

  .bg-img {
    z-index: 1;
  }

  .logo {
    width: 4rem;
    margin: 0rem auto 1rem;
    position: relative;
    z-index: 1;
  }

  .member-center {
    padding-top: 1rem;
  }

  .bg-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .member-header {
    width: calc(100%);
    height: 2rem;
    margin: 0 auto 0.5rem;
    background-image: url('~@/assets/images/member_center/gold-hr.png');
    position: relative;
    z-index: 1;

    .member-header-back {
      transform: rotate(180deg);
      width: 0.7rem;
      left: 0.5rem;
      position: absolute;
      top: 0.4rem;
      z-index: 1;
    }

    .member-header-title {
      color: white;
      font-size: 1rem;
      text-align: center;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      width: 40%;
      top: 50%;
      transform: translateY(-50%);
    }

    .back-btn {
      width: 2rem;
      height: 2rem;
    }
  }

  .member-head {
    width: calc(100% - 0.5rem);
    height: 8rem;
    margin: 0 auto 0.5rem;
    background-image: url('~@/assets/images/member_center/member_head_m.png');
    background-size: 100% 100%;
    position: relative;
    z-index: 1;

    > .user-info {
      position: absolute;
      left: 1.5rem;
      bottom: 1.5rem;

      > .avatar {
        width: 2rem;
        margin-right: 0.6rem;
      }

      > .info {
        font-size: 0.7rem;
        color: white;
        margin-bottom: 0;
      }
    }
  }

  .head-title {
    margin-bottom: 3rem;
    font-size: 0.9rem !important;
  }

  .crown {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .member-tabs {
    width: calc(100% - 0.5rem);
    margin: 0 auto 0.5rem;
    display: flex;
  }
  .tab {
    width: 30%;
    height: 2.5rem;
    background-image: url('~@/assets/images/member_center/member_tab.png');
    background-size: 100% 100%;
  }
  .tab-icon {
    width: auto;
    height: 1.2rem;
  }
  .tab-title {
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    color: #333;
    letter-spacing: 0.3rem;
  }

  .page-content {
    width: 100%;
    background: #2b2827;
  }

  .admin-page-content {
    padding: 0.5rem 0.5rem 5rem;
  }

  .customer-btn {
    width: 50px;
    height: 50px;
    top: 10%;
    right: 0;
    background: url('~@/assets/images/customer-service.png');
    background-size: cover;
    cursor: pointer;
  }

  .syphon-btn {
    width: 50px;
    height: 50px;
    top: calc(10% + 60px);
    right: 0;
    background: url('~@/assets/images/syphon.png');
    background-size: cover;
    cursor: pointer;
  }

  .vpn-btn {
    width: 50px;
    height: 50px;
    top: calc(10% + 120px);
    right: 0;
    line-height: 1;
    border-radius: 5px;
    font-size: 13px;
  }
}
</style>
