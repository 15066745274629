<template>
  <div v-if="show" class="member-enters">
    <div v-for="(entry, idx) in entries" :key="idx" class="entry-container">
      <router-link
        v-if="entry.type === 'link' && !entry.hide"
        :class="[
          'member-enter',
          'flex-ac',
          'flex-jb',
          { active: $route.fullPath.startsWith(entry.path) },
        ]"
        :target="entry.blank ? '_blank' : '_self'"
        :to="entry.path"
      >
        <menu-item-inner :entry="entry"></menu-item-inner>
      </router-link>
      <a
        v-if="entry.type === 'href' && !entry.hide"
        :class="[
          'member-enter',
          'flex-ac',
          'flex-jb',
          { active: entry.active },
        ]"
        :href="entry.path"
        target="_blank"
      >
        <menu-item-inner :entry="entry"></menu-item-inner>
      </a>
      <div v-if="entry.type === 'divider'" class="gold-hr"></div>
    </div>
  </div>
</template>

<script>
import MenuItemInner from './menu-item-inner.vue'
import usr from '@/mixins/usr'

export default {
  name: 'member-menu',
  mixins: [usr],
  components: {
    MenuItemInner
  },
  data() {
    return {}
  },
  computed: {
    entries() {
      console.log(this.userInfo)
      const entries = [
        {
          type: 'link',
          label: '银行卡',
          path: '/member_center/bank-card',
          icon: 'BankCardIcon',
          hide: process.env.NODE_ENV !== 'development'
        },
        {
          type: 'link',
          label: '下注记录',
          path: '/member_center/bets_log',
          icon: 'MoneylogIcon'
        },
        {
          type: 'link',
          label: '充值记录',
          path: '/member_center/deposit_log',
          icon: 'DepositlogIcon'
        },
        {
          type: 'link',
          label: '资金明细',
          path: '/member_center/money_log',
          icon: 'MoneylogIcon'
        },
        {
          type: 'link',
          label: '额度转换查询',
          path: '/member_center/transfer_check',
          icon: 'TransLogIcon'
        },
        {
          type: 'divider'
        },
        {
          type: 'link',
          label: '额度转换',
          path: '/member_center/transfer_money',
          icon: 'Transfer',
          hide: this.userInfo.power_switch !== '1'
        },
        {
          type: 'divider'
        },
        {
          type: 'link',
          label: '邀请二维码',
          path: '/member_center/invite',
          icon: 'QrcodeIcon'
       },
        {
          type: 'href',
          label: '客服中心',
          path: `https://kf888.club/?username=${this.$store.state.username}`,
          icon: 'CustomerServiceIcon'
        },
        {
          type: 'link',
          label: '消息中心',
          path: '/member_center/notification',
          icon: 'MessageIcon'
        },
        {
          type: 'link',
          label: '关于我们',
          path: '/member_center/about/us',
          icon: 'InfoIcon'
        },
        {
          type: 'divider'
        },
        {
          type: 'link',
          label: '隐私说明',
          path: '/member_center/about/privacy',
          icon: 'PrivatePolicyIcon'
        },
        {
          type: 'link',
          label: '合约条款',
          path: '/member_center/about/agreement',
          icon: 'AgreementIcon'
        },
        {
          type: 'link',
          label: '联络我们',
          path: '/contact_us',
          icon: 'ContactUsIcon'
        },
        {
          type: 'link',
          label: 'APP 下载',
          path: '/download_app',
          icon: 'MobileIcon'
        },
        {
          type: 'link',
          label: '常见问题',
          // path: 'https://docs.google.com/document/d/e/2PACX-1vQ-bU5JCHEVahZNT-lafzvRl7mbgAj-LabXP50cF2Z3RNmluLPwmFhOf6x43inDs3fMnW_2OnqD8bzM/pub',
          path: '/tutorial/faq',
          blank: true,
          icon: ''
        },
        // {
        //   type: 'link',
        //   label: '注册教学',
        //   // path: 'https://docs.google.com/document/u/1/d/e/2PACX-1vS__o4ZXb0X1IXqsIjqpcLZ3MLi-EtfjW2ILVNPUzhR0hb6MgZ2xj5KW9cpURYDHBQ0_mOLRD80NZrM/pub',
        //   path: '/tutorial/register',
        //   blank: true,
        //   icon: '',
        // },
        {
          type: 'link',
          label: '设置',
          path: '/member_center/setting',
          icon: 'SettingIcon'
        }
      ]

      return entries
    },
    show() {
      return (
        (!this.$store.state.isMobileLayout ||
          this.$route.fullPath === '/member_center') &&
        !this.$route.fullPath.includes('/about')
      )
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.header_title = this.routeName
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.member-enters {
  width: 10rem;
  padding-bottom: 2.5rem;
  position: relative;
  margin-bottom: 3rem;

  .member-enter {
    height: 2.5rem;
    background-color: #2e2c2d;
    padding: 0 0.5rem;
    width: 100%;

    &:hover,
    &.active {
      ::v-deep .enter-title {
        color: $theme-color;
      }

      ::v-deep .enter-icon {
        path,
        g {
          fill: $theme-color;
        }
      }
    }
  }

  .gold-hr {
    width: 100%;
    height: 0.8rem;
    background-image: url('~@/assets/images/member_center/gold-hr.png');
    background-size: 100% 100%;
  }

  .entry-container:last-child {
    .inner {
      border-bottom: 0;
    }
  }
}

@include md {
  .member-enters {
    width: 100%;
    padding-bottom: 2.5rem;

    .member-enter {
      height: 2.5rem;
      background-color: #2e2c2d;
    }
  }
}
</style>
