var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"member-enters"},_vm._l((_vm.entries),function(entry,idx){return _c('div',{key:idx,staticClass:"entry-container"},[(entry.type === 'link' && !entry.hide)?_c('router-link',{class:[
        'member-enter',
        'flex-ac',
        'flex-jb',
        { active: _vm.$route.fullPath.startsWith(entry.path) } ],attrs:{"target":entry.blank ? '_blank' : '_self',"to":entry.path}},[_c('menu-item-inner',{attrs:{"entry":entry}})],1):_vm._e(),(entry.type === 'href' && !entry.hide)?_c('a',{class:[
        'member-enter',
        'flex-ac',
        'flex-jb',
        { active: entry.active } ],attrs:{"href":entry.path,"target":"_blank"}},[_c('menu-item-inner',{attrs:{"entry":entry}})],1):_vm._e(),(entry.type === 'divider')?_c('div',{staticClass:"gold-hr"}):_vm._e()],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }